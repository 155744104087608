import React from "react";
import './Phase1HomeModule.css';
import ThankYouBanner from '../../asset/banners/ThankYouBanner.png';

const Phase1Home = (props) => {

    const { labelList } = props.state;

    return (
        <React.Fragment>
            <div className='home-main-div'>
                <div className='restaurant-container'>

                    <h2 className='login-title'>{labelList.filter(i => i.key === 'Phase1.ThankYou')[0]?.value}</h2>
                    <label className="home-label">{labelList.filter(i => i.key === 'Phase1.AlreadyPart')[0]?.value}</label>
                    <label className="home-label">{labelList.filter(i => i.key === 'Phase1.AgentWillContact')[0]?.value}</label>

                    <div>
                        <div className='thank-you'>
                            <img src={ThankYouBanner} alt='CheckHome' />
                        </div>
                        
                        <div className='home-button-div'>
                            <button type='submit' onClick={props.onGoingNext}>{labelList.filter(i => i.key === 'Phase2.OkButton')[0]?.value}</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Phase1Home;