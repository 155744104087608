import React from 'react';
import { Formik, Field, Form } from 'formik';
import FormikInput from '../common/formik/FormikInput';
import FormikSelect from '../common/formik/FormikSelect';
import './LoginModule.css';
import * as commonMethods from '../../common/CommonMethods';
import Header from '../common/header/Header';

const LoginWithEmail = (props) => {

    const { labelList, loginDetails, formValidationSchema } = props.state;

    return (
        <div className='login-main-div'>
            <Formik
                initialValues={loginDetails}
                enableReinitialize='true'
                innerRef={props.formikRef}
                validationSchema={formValidationSchema}
                onSubmit={fields => {
                    props.onLogin(fields)
                }}
            >
                {(formik) => (
                    <React.Fragment >

                        <Header />

                        <div className='login-container'>

                            <h2 className='login-title'>{labelList.filter(i => i.key === 'Login.SignIn')[0]?.value}</h2>

                            <Form noValidate>

                                <div className='form-group'>
                                    <div className="email-div">
                                        <Field name='userName' id='userName' type='input' component={FormikInput}
                                            placeholder={labelList.filter(i => i.key === 'Login.EmailLabel')[0]?.value} />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="pass-div">
                                        <Field name="password" id="password" type="password" onChange={() => { }} component={FormikInput}
                                            placeholder={labelList.filter(i => i.key === 'Login.PasswordLabel')[0]?.value} />
                                        <i className="fa fa-eye" onClick={(event) => commonMethods.showHidePasswordLogin(event, 'password')}></i>
                                    </div>
                                </div>

                                <div className='change-login-option'>
                                    <label onClick={props.onChangeLoginOptionHandler}><span >{labelList.filter(i => i.key === 'Login.LoginWithMobileLink')[0]?.value}</span></label>
                                </div>

                                <div className={(formik.isValid && formik.dirty) ? 'login-button-div' : 'login-button-div disable-btn'} >
                                    <button type='submit' >{labelList.filter(i => i.key === 'Login.LoginButton')[0]?.value}</button>
                                </div>

                                <div className='signup-div'>
                                    <label>{labelList.filter(i => i.key === 'Login.DoNotHaveAccountLabel')[0]?.value}</label>&nbsp;
                                    <label onClick={props.signUp} className='signup-label'> {labelList.filter(i => i.key === 'Login.SignUpButton')[0]?.value}</label>
                                </div>

                                <div className="forgot-pass-div">  
                                    <a onClick={props.routeToForgotPassword}
                                    > {labelList.filter(i => i.key === 'Login.ForgotPasswordLink')[0]?.value}</a>
                                </div>
                                
                                <div className='language-div value-available'>
                                    <i className='fas fa-globe-americas' ></i>
                                    <Field name='language' id='language' className='decorated' type='select' onChange={props.onChangeLanguageListHandler} option={props.state.languageList} component={FormikSelect} />
                                </div>
                            </Form>
                        </div>
                    </React.Fragment >
                )}
            </Formik >
        </div >
    )
}

export default LoginWithEmail;