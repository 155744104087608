import React from "react";
import { Formik, Field, Form } from 'formik';
import FormikInput from '../common/formik/FormikInput';
import FormikSelect from '../common/formik/FormikSelect';
import FormikMultiSelect from '../common/formik/FormikMultiSelect';
import './RestaurantInfoModule.css';
import * as commonMethods from '../../common/CommonMethods';
import ReactTooltip from 'react-tooltip';
import LegalId from '../../asset/logos/LegalId.png';
import PlacesAutocomplete from 'react-places-autocomplete';
import { UploadIconSvg } from "../../common/CommonSvgs";
import { AppEnum } from "../../common/AppEnum";

const RestaurantInfo = (props) => {

    const { labelList, restaurantInfo, countryList, formValidationSchema, taxRegimePersonalConfig, isPersonalTaxRegime, isLegalTaxRegime,
        restaurantAddress, serviceTypeList, taxRegimeList, legalIneList, taxRegimeLegalConfig, latLng, serviceTypeCDs, isDropDownOpen,
        isFrontIneUploaded, isBackIneUploaded, isTaxIdUploaded, isAttorneyUploaded, isConstitutiveActUploaded, remarks,
        isSellerProfileRejected, isFrontInePdf, isBackInePdf, isTaxIdPdf, isAttorneyPdf, isConstitutiveActPdf, countryNameCode,
        fileCategories, ineFrontPdfBlob, ineBackPdfBlob, taxIdPdfBlob, attorneypdfBlob, constitutivePdfBlob, mobileNoMaxLength } = props.state;

    return (
        <div className='personal-main-div'>
            <Formik
                initialValues={restaurantInfo}
                enableReinitialize='true'
                validationSchema={formValidationSchema}
                innerRef={props.formikRef}
                onSubmit={fields => {
                    props.saveRestaurantInfo(fields);
                }}
            >
                {(formik) => (
                    <React.Fragment >
                        <div className='personal-info-container'>

                            <div>
                                <h2 className='login-title'>{labelList.filter(i => i.key === 'Phase1.RestaurantInfo')[0]?.value}</h2>

                                <Form noValidate>
                                    {
                                        (remarks) &&
                                        <div className="address-remark">
                                            <label>{labelList.filter(i => i.key === 'Phase1.Remark')[0].value}
                                                <span>{remarks}</span>
                                            </label>
                                        </div>
                                    }

                                    <div className="input-sub-div">
                                        <Field name="restaurantName" placeholder={labelList.filter(i => i.key === 'Phase1.RestaurantName')[0]?.value}
                                            disabled={isSellerProfileRejected ? false : true} maxLength='50' id="restaurantName" type="text" component={FormikInput} />
                                    </div>

                                    <div className={`${'res-no-div'} ${props.state.countryCode && 'value-available'}`}>
                                        <Field value={props.state.countryCode} option={countryList} disabled={true}
                                            name='country' id='country' type='select' component={FormikSelect} />
                                        <span className='mobile-no-span'>
                                            <Field maxLength={mobileNoMaxLength} name='restaurantMobileNo' placeholder={labelList.filter(i => i.key === 'Phase1.RestaurantPhone')[0]?.value}
                                                disabled={isSellerProfileRejected ? false : true} id='restaurantMobileNo' type='tel' onKeyDown={commonMethods.updateNumber} component={FormikInput} />
                                        </span>
                                    </div>

                                    <div className="input-sub-div">
                                        <Field options={serviceTypeList}
                                            value={serviceTypeList.filter(option => {
                                                return option.cd === serviceTypeCDs.filter(i => i.value === option.cd)[0]?.value
                                            })}
                                            isDisabled={isSellerProfileRejected ? false : true}
                                            onChange={props.onChangeServiceType}
                                            placeholder={labelList.filter(i => i.key === 'Phase1.ToSelectLabel')[0]?.value}
                                            name='serviceTypeCDs' id='serviceTypeCDs' type='select' component={FormikMultiSelect} />
                                    </div>

                                    <div className="input-sub-div">
                                        <PlacesAutocomplete
                                            value={restaurantAddress}
                                            onChange={props.onChangeRestaurantAddress}
                                            onSelect={props.onSelectAddressHandler}
                                            searchOptions={{ componentRestrictions: { country: [countryNameCode] } }}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div >
                                                    <div className={props.state.addressApprovalCD ? "search-address-div disable-search" : "search-address-div"}>
                                                        <input
                                                            {...getInputProps({
                                                                placeholder: labelList.filter(i => i.key === 'Phase1.RestAddress')[0]?.value,
                                                                className: 'language-input-box',
                                                            })}
                                                            disabled={props.state.addressApprovalCD}
                                                            onBlur={() => { }}
                                                            id="restaurantAddress"
                                                            name="restaurantAddress"
                                                        />
                                                        {restaurantAddress && <i className={props.state.addressApprovalCD ? "fas fa-times fa-disabled" : "fas fa-times"} onClick={props.resetRestarauntAddressField}></i>}
                                                    </div>

                                                    <div>
                                                        {loading && <div className="animated loader"></div>}
                                                        {restaurantAddress && suggestions.map((suggestion, index) => {
                                                            // inline style for demonstration purpose
                                                            const style = suggestion.active
                                                                ? { backgroundColor: '#FF5100', cursor: 'pointer', color: '#ffff', padding: '4px 5px 2px 10px' }
                                                                : { backgroundColor: '#ffffff', cursor: 'pointer', padding: '4px 5px 2px 10px' };
                                                            return (
                                                                <div className="places-suggestion-div" key={index}>
                                                                    <hr />
                                                                    <div  {...getSuggestionItemProps(suggestion, { style, })} >
                                                                        <span><i className="fas fa-map-marker-alt"></i>&nbsp;{suggestion.description}</span>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                    </div>

                                    {
                                        (props.state.adressRemark) &&
                                        <div className="address-remark">
                                            <label>{labelList.filter(i => i.key === 'Phase1.Remark')[0].value}
                                                <span>{props.state.adressRemark}</span>
                                            </label>
                                        </div>
                                    }

                                    <div className="input-sub-div">
                                        <Field name="zipCode" id="zipCode" maxLength="6" type='tel' onKeyDown={commonMethods.updateNumber} component={FormikInput}
                                            disabled={props.state.addressApprovalCD} placeholder={labelList.filter(i => i.key === 'Phase1.ZipCode')[0]?.value} />
                                    </div>

                                    <div className="input-sub-div">
                                        <Field onChange={props.onChangeTaxRegimeHandler} option={taxRegimeList} disabled={props.isSellerRejected}
                                            name='configTaxRegimeNo' id='configTaxRegimeNo' type='select' component={FormikSelect} />
                                    </div>

                                    <div className="input-sub-div">
                                        <Field maxLength="100" name='businessName' id='businessName' type='text' component={FormikInput}
                                            disabled={isSellerProfileRejected ? false : true} placeholder={labelList.filter(i => i.key === 'Phase1.CompanyName')[0]?.value} />
                                    </div>

                                    <div className="input-sub-div">
                                        <Field maxLength="13" name='rfc' id='rfc' type='text' component={FormikInput}
                                            disabled={isSellerProfileRejected ? false : true} placeholder={labelList.filter(i => i.key === 'Phase1.RFC')[0]?.value} />
                                    </div>

                                    <label className='legal-info'>{labelList.filter(i => i.key === 'Phase1.InfoOfLegal')[0]?.value}</label>

                                    <div className="input-sub-div">
                                        <Field maxLength="50" name='legalRepresentativeFirstName' id='legalRepresentativeFirstName' type='text' component={FormikInput}
                                            disabled={isSellerProfileRejected ? false : true} placeholder={labelList.filter(i => i.key === 'Phase1.NameOfLegal')[0]?.value} />
                                    </div>

                                    <div className="input-sub-div">
                                        <Field maxLength="50" name='legalRepresentativeLastName' id='legalRepresentativeLastName' type='text' component={FormikInput}
                                            disabled={isSellerProfileRejected ? false : true} placeholder={labelList.filter(i => i.key === 'Phase1.LastNameOfLegal')[0]?.value} />
                                    </div>

                                    <div className="input-sub-div">
                                        <Field onChange={() => { }} option={legalIneList} onClick={props.onClickDropDown} onBlur={props.onBlurDropDown}
                                            disabled={isSellerProfileRejected ? false : true} name='legalRepresentativenConfigNo' id='legalRepresentativenConfigNo' type='select' component={FormikSelect} />
                                    </div>

                                    <div className="legal-id-div">

                                        <Field maxLength='10' name='legalRepresentativeNumber' id='legalRepresentativeNumber' type='text' component={FormikInput}
                                            disabled={isSellerProfileRejected ? false : true} placeholder={labelList.filter(i => i.key === 'Phase1.IdOfLegal')[0]?.value} />
                                        <div className="legal-tooltip">

                                            <i className="fas fa-info-circle tooltip-icon" data-tip data-for='legal-id' ></i>
                                            <ReactTooltip className='tooltip-image-width' id='legal-id' aria-haspopup='true' type='success' disable={isDropDownOpen} place="left" multiline={true}>
                                                <img src={LegalId} alt='Tooltip' />
                                            </ReactTooltip>
                                        </div>

                                    </div>

                                    <label className='info-label'>{labelList.filter(i => i.key === 'Phase1.AddDocuments')[0]?.value}</label>

                                    <label className='note-info-label'>{labelList.filter(i => i.key === 'Phase1.Note3mb')[0]?.value}</label>

                                    {
                                        isLegalTaxRegime &&
                                        taxRegimeLegalConfig.map((regime, index) => {
                                            return (
                                                <div key={index} className={regime.fileCategoryUId ? regime.isApproved === false ? "upload-regime-div" :
                                                    "upload-regime-div regime-img" : "upload-regime-div"}>
                                                    {
                                                        AppEnum.RestaurantAttachmentUId.IneFrontPhotography === (regime.fileCategoryUId ? regime.fileCategoryUId : regime.uId) &&

                                                        <div className="file-image-div">

                                                            <label className="res-doc-label">{labelList.filter(i => i.key === "Phase1.INEFront")[0]?.value}</label>
                                                            {
                                                                props.state.isFrontIneUploaded && isFrontInePdf &&
                                                                <div className="pdf-icon-div">
                                                                    <i className="fas fa-file-pdf" onClick={() => props.previewPdfDocument(ineFrontPdfBlob)}></i>
                                                                    <span onClick={() => props.previewPdfDocument(ineFrontPdfBlob)}>{fileCategories.length > 0 ? fileCategories.filter(i => i.fileCategoryUId === AppEnum.RestaurantAttachmentUId.IneFrontPhotography)[0]?.fileName
                                                                        : taxRegimeLegalConfig.filter(i => i.fileCategoryUId === AppEnum.RestaurantAttachmentUId.IneFrontPhotography)[0]?.fileName}</span>
                                                                </div>
                                                            }

                                                            {props.state.isFrontIneUploaded && !isFrontInePdf && <img id="INEFront" src={regime.fileUrl ? regime.fileUrl : ''}
                                                                alt="Front Ine" onClick={() => commonMethods.showUploadedAttachment('INEFront')} />}
                                                        </div>
                                                    }
                                                    {
                                                        AppEnum.RestaurantAttachmentUId.IneBackPhotography === (regime.fileCategoryUId ? regime.fileCategoryUId : regime.uId) &&

                                                        <div className="file-image-div">

                                                            <label className="res-doc-label">{labelList.filter(i => i.key === "Phase1.INEBack")[0]?.value}</label>
                                                            {
                                                                props.state.isBackIneUploaded && isBackInePdf &&
                                                                <div className="pdf-icon-div">
                                                                    <i className="fas fa-file-pdf" onClick={() => props.previewPdfDocument(ineBackPdfBlob)}></i>
                                                                    <span onClick={() => props.previewPdfDocument(ineBackPdfBlob)}>{fileCategories.length > 0 ? fileCategories.filter(i => i.fileCategoryUId === AppEnum.RestaurantAttachmentUId.IneBackPhotography)[0]?.fileName
                                                                        : taxRegimeLegalConfig.filter(i => i.fileCategoryUId === AppEnum.RestaurantAttachmentUId.IneBackPhotography)[0]?.fileName}</span>
                                                                </div>
                                                            }

                                                            {props.state.isBackIneUploaded && !isBackInePdf && <img id="INEBack" src={regime.fileUrl ? regime.fileUrl : ''}
                                                                alt="Back Ine" onClick={() => commonMethods.showUploadedAttachment("INEBack")} />}
                                                        </div>
                                                    }
                                                    {
                                                        AppEnum.RestaurantAttachmentUId.TaxIdentificationCard === (regime.fileCategoryUId ? regime.fileCategoryUId : regime.uId) &&

                                                        <div className="file-image-div">

                                                            <label className="res-doc-label">{labelList.filter(i => i.key === "Phase1.TaxId")[0]?.value}</label>

                                                            {
                                                                props.state.isTaxIdUploaded && isTaxIdPdf &&
                                                                <div className="pdf-icon-div">
                                                                    <i className="fas fa-file-pdf" onClick={() => props.previewPdfDocument(taxIdPdfBlob)}></i>
                                                                    <span onClick={() => props.previewPdfDocument(taxIdPdfBlob)}>{fileCategories.length > 0 ? fileCategories.filter(i => i.fileCategoryUId === AppEnum.RestaurantAttachmentUId.TaxIdentificationCard)[0]?.fileName
                                                                        : taxRegimeLegalConfig.filter(i => i.fileCategoryUId === AppEnum.RestaurantAttachmentUId.TaxIdentificationCard)[0]?.fileName}</span>
                                                                </div>
                                                            }

                                                            {props.state.isTaxIdUploaded && !isTaxIdPdf && <img id="TaxId" src={regime.fileUrl ? regime.fileUrl : ''}
                                                                alt="Tax Id" onClick={() => commonMethods.showUploadedAttachment("TaxId")} />}

                                                        </div>
                                                    }
                                                    {
                                                        AppEnum.RestaurantAttachmentUId.PowerofAttorney === (regime.fileCategoryUId ? regime.fileCategoryUId : regime.uId) &&
                                                        <div className="file-image-div">

                                                            <label className="res-doc-label">{labelList.filter(i => i.key === "Phase1.PowerOfAttorney")[0]?.value}</label>

                                                            {
                                                                props.state.isAttorneyUploaded && isAttorneyPdf &&
                                                                <div className="pdf-icon-div">
                                                                    <i className="fas fa-file-pdf" onClick={() => props.previewPdfDocument(attorneypdfBlob)}></i>
                                                                    <span onClick={() => props.previewPdfDocument(attorneypdfBlob)}>{fileCategories.length > 0 ? fileCategories.filter(i => i.fileCategoryUId === AppEnum.RestaurantAttachmentUId.PowerofAttorney)[0]?.fileName
                                                                        : taxRegimeLegalConfig.filter(i => i.fileCategoryUId === AppEnum.RestaurantAttachmentUId.PowerofAttorney)[0]?.fileName}</span>
                                                                </div>
                                                            }

                                                            {props.state.isAttorneyUploaded && !isAttorneyPdf && <img id="PowerOfAttorney" src={regime.fileUrl ? regime.fileUrl : ''}
                                                                alt="Power of attorney" onClick={() => commonMethods.showUploadedAttachment("PowerOfAttorney")} />}

                                                        </div>
                                                    }
                                                    {
                                                        AppEnum.RestaurantAttachmentUId.ArticlesofIncorporation === (regime.fileCategoryUId ? regime.fileCategoryUId : regime.uId) &&

                                                        <div className="file-image-div">

                                                            <label className="res-doc-label">{labelList.filter(i => i.key === "Phase1.ConstitutiveAct")[0]?.value}</label>

                                                            {
                                                                props.state.isConstitutiveActUploaded && isConstitutiveActPdf &&
                                                                <div className="pdf-icon-div">
                                                                    <i className="fas fa-file-pdf" onClick={() => props.previewPdfDocument(constitutivePdfBlob)}></i>
                                                                    <span onClick={() => props.previewPdfDocument(constitutivePdfBlob)}>{fileCategories.length > 0 ? fileCategories.filter(i => i.fileCategoryUId === AppEnum.RestaurantAttachmentUId.ArticlesofIncorporation)[0]?.fileName
                                                                        : taxRegimeLegalConfig.filter(i => i.fileCategoryUId === AppEnum.RestaurantAttachmentUId.ArticlesofIncorporation)[0]?.fileName}</span>
                                                                </div>
                                                            }

                                                            {props.state.isConstitutiveActUploaded && !isConstitutiveActPdf && <img id="ConstitutiveAct" src={regime.fileUrl ? regime.fileUrl : ''}
                                                                alt="Constitutive act" onClick={() => commonMethods.showUploadedAttachment("ConstitutiveAct")} />}

                                                        </div>
                                                    }
                                                    {
                                                        (!regime.isApproved && regime.actionNote) &&
                                                        <div className="red">
                                                            <label>{labelList.filter(i => i.key === 'Phase1.Remark')[0].value}
                                                                <span>{regime.actionNote}</span>
                                                            </label>
                                                        </div>
                                                    }
                                                    {(regime.fileUrl ? regime.isApproved === false : true) &&
                                                        <label htmlFor={regime.fileCategoryUId ? regime.fileCategoryUId : regime.uId} className="upload-regime-title" >
                                                            {labelList.filter(i => i.key === "Phase2.Upload")[0]?.value}{UploadIconSvg}
                                                        </label>
                                                    }

                                                    <input type="file" id={regime.fileCategoryUId ? regime.fileCategoryUId : regime.uId} accept={"image/png, image/jpeg, image/jpg, application/pdf, capture=camera"}
                                                        onChange={(event) => props.uploadTaxRegimeDocument(event, regime)} onClick={(event) => { commonMethods.SetInputTypeFileFieldNull(event) }}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        isPersonalTaxRegime &&
                                        taxRegimePersonalConfig.map((regime, index) => {
                                            return (
                                                <div key={index} className={regime.fileCategoryUId ? regime.isApproved === false ? "upload-regime-div" :
                                                    "upload-regime-div regime-img" : "upload-regime-div"}>

                                                    {
                                                        AppEnum.RestaurantAttachmentUId.IneFrontPhotography === (regime.fileCategoryUId ? regime.fileCategoryUId : regime.uId) &&

                                                        <div className="file-image-div">
                                                            <label className="res-doc-label">{labelList.filter(i => i.key === "Phase1.INEFront")[0]?.value}</label>

                                                            {
                                                                props.state.isFrontIneUploaded && isFrontInePdf &&
                                                                <div className="pdf-icon-div">
                                                                    <i className="fas fa-file-pdf" onClick={() => props.previewPdfDocument(ineFrontPdfBlob)}></i>
                                                                    <span onClick={() => props.previewPdfDocument(ineFrontPdfBlob)}>{fileCategories.length > 0 ? fileCategories.filter(i => i.fileCategoryUId === AppEnum.RestaurantAttachmentUId.IneFrontPhotography)[0]?.fileName
                                                                        : taxRegimePersonalConfig.filter(i => i.fileCategoryUId === AppEnum.RestaurantAttachmentUId.IneFrontPhotography)[0]?.fileName}</span>
                                                                </div>
                                                            }

                                                            {props.state.isFrontIneUploaded && !isFrontInePdf && <img id="INEFront" src={regime.fileUrl ? regime.fileUrl : ''}
                                                                alt="Front Ine" onClick={() => commonMethods.showUploadedAttachment("INEFront")} />}

                                                        </div>
                                                    }

                                                    {
                                                        AppEnum.RestaurantAttachmentUId.IneBackPhotography === (regime.fileCategoryUId ? regime.fileCategoryUId : regime.uId) &&

                                                        <div className="file-image-div">

                                                            <label className="res-doc-label">{labelList.filter(i => i.key === "Phase1.INEBack")[0]?.value}</label>

                                                            {
                                                                props.state.isBackIneUploaded && isBackInePdf &&
                                                                <div className="pdf-icon-div">
                                                                    <i className="fas fa-file-pdf" onClick={() => props.previewPdfDocument(ineBackPdfBlob)}></i>
                                                                    <span onClick={() => props.previewPdfDocument(ineBackPdfBlob)}>{fileCategories.length > 0 ? fileCategories.filter(i => i.fileCategoryUId === AppEnum.RestaurantAttachmentUId.IneBackPhotography)[0]?.fileName
                                                                        : taxRegimePersonalConfig.filter(i => i.fileCategoryUId === AppEnum.RestaurantAttachmentUId.IneBackPhotography)[0]?.fileName}</span>
                                                                </div>
                                                            }

                                                            {props.state.isBackIneUploaded && !isBackInePdf && <img id="INEBack" src={regime.fileUrl ? regime.fileUrl : ''}
                                                                alt="Back Ine" onClick={() => commonMethods.showUploadedAttachment("INEBack")} />}

                                                        </div>
                                                    }
                                                    {
                                                        AppEnum.RestaurantAttachmentUId.TaxIdentificationCard === (regime.fileCategoryUId ? regime.fileCategoryUId : regime.uId) &&

                                                        <div className="file-image-div">
                                                            <label className="res-doc-label">{labelList.filter(i => i.key === "Phase1.TaxId")[0]?.value}</label>

                                                            {
                                                                props.state.isTaxIdUploaded && isTaxIdPdf &&
                                                                <div className="pdf-icon-div">
                                                                    <i className="fas fa-file-pdf" onClick={() => props.previewPdfDocument(taxIdPdfBlob)}></i>
                                                                    <span onClick={() => props.previewPdfDocument(taxIdPdfBlob)}>{fileCategories.length > 0 ? fileCategories.filter(i => i.fileCategoryUId === AppEnum.RestaurantAttachmentUId.TaxIdentificationCard)[0]?.fileName
                                                                        : taxRegimePersonalConfig.filter(i => i.fileCategoryUId === AppEnum.RestaurantAttachmentUId.TaxIdentificationCard)[0]?.fileName}</span>
                                                                </div>
                                                            }

                                                            {props.state.isTaxIdUploaded && !isTaxIdPdf && <img id="TaxId" src={regime.fileUrl ? regime.fileUrl : ''}
                                                                alt="Tax Id" onClick={() => commonMethods.showUploadedAttachment("TaxId")} />}

                                                        </div>
                                                    }
                                                    {
                                                        (!regime.isApproved && regime.actionNote) &&

                                                        <div className="red-remark">
                                                            <label>{labelList.filter(i => i.key === 'Phase1.Remark')[0].value}
                                                                <span>{regime.actionNote}</span>
                                                            </label>
                                                        </div>
                                                    }
                                                    {(regime.fileUrl ? regime.isApproved === false : true) &&

                                                        <label htmlFor={regime.fileCategoryUId ? regime.fileCategoryUId : regime.uId} className="upload-regime-title" >
                                                            {labelList.filter(i => i.key === "Phase2.Upload")[0]?.value}{UploadIconSvg}
                                                        </label>
                                                    }

                                                    <input type="file" id={regime.fileCategoryUId ? regime.fileCategoryUId : regime.uId} accept={"image/png, image/jpeg, image/jpg, application/pdf, capture=camera"}
                                                        onChange={(event) => props.uploadTaxRegimeDocument(event, regime)} onClick={(event) => { commonMethods.SetInputTypeFileFieldNull(event) }}
                                                    />

                                                </div>
                                            )
                                        })
                                    }

                                    <div className={(formik.isValid) ? (isPersonalTaxRegime ? (serviceTypeCDs.length > 0 && latLng && isFrontIneUploaded && isBackIneUploaded && isTaxIdUploaded ? 'submit-btn-div' : 'submit-btn-div disable-btn') :
                                        isLegalTaxRegime ? (serviceTypeCDs.length > 0 && latLng && isFrontIneUploaded && isBackIneUploaded && isTaxIdUploaded && isAttorneyUploaded && isConstitutiveActUploaded ? 'submit-btn-div' : 'submit-btn-div disable-btn') : 'submit-btn-div disable-btn') : 'submit-btn-div disable-btn'} >
                                        <button type='submit'>{labelList.filter(i => i.key === 'Login.SignUpButton')[0]?.value}</button>
                                    </div>

                                </Form>
                            </div>
                        </div>
                    </React.Fragment >
                )}
            </Formik >
        </div >
    )
}

export default RestaurantInfo;