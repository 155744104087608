import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RestaurantDocument from "../../component/restaurant-document/RestaurantDocument";
import documentState, { BankStatementModel, numericRegex, UploadFileModel } from './RestaurantDocumentState';
import * as documentService from '../../service/auth/document/Document.Service';
import { AppEnum } from "../../common/AppEnum";
import * as commonMethods from '../../common/CommonMethods';
import { toast } from "react-toastify";
import * as layoutService from '../../service/auth/privateLayout/Layout.Service';
import { regexForAcceptAllExceptWhitespace } from "../restaurant-info/RestaurantInfoState";

// it removes all the docs which are required to upload after login on mobile app
export const removeAfterLoginDocs = (restaurantDocuments) => {
    let documents = restaurantDocuments.filter(i => (
        i.fileCategoryUId !== AppEnum.RestDocFileCategoryUId.BannerImage &&
        i.fileCategoryUId !== AppEnum.RestDocFileCategoryUId.Image1 &&
        i.fileCategoryUId !== AppEnum.RestDocFileCategoryUId.Image2 &&
        i.fileCategoryUId !== AppEnum.RestDocFileCategoryUId.RestaurantDefaultImage
    ))
    return documents;
}

const RestaurantDocumentContainer = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [state, setState] = useState(documentState);

    useEffect(() => {
        let masterApiData = JSON.parse(localStorage.getItem('languageApiLabels'));
        setState((prevState) => { return { ...prevState, labelList: masterApiData } });

        let restaurantDocuments = location?.state ? location?.state?.restaurantDocuments : undefined;

        getDocumentJsxList(restaurantDocuments);
        getBankStatementData();
    }, [])

    // it fetches all the document attachment configuration (if location state is empty)
    const getDocumentJsxList = (restaurantDocuments) => {

        if (restaurantDocuments) {
            // for clearing history state values which are passed to this route 
            window?.history?.replaceState({}, restaurantDocuments)
            // location.state = undefined;
            let allowedRestaurantDocuments = removeAfterLoginDocs(restaurantDocuments);
            checkPrevUploadedDocs(allowedRestaurantDocuments);
            setRestaurantDocumentList(allowedRestaurantDocuments);
        }
        else {
            layoutService.getSellerProfile().then((response) => {
                if (response) {
                    let allowedRestaurantDocuments = removeAfterLoginDocs(response.restaurantDocuments);
                    checkPrevUploadedDocs(allowedRestaurantDocuments);
                    setRestaurantDocumentList(allowedRestaurantDocuments);
                }
            })
        }
    }

    //  check if all the documents are uploaded or not
    const checkPrevUploadedDocs = (restaurantDocuments) => {

        restaurantDocuments.forEach(document => {
            if (document.fileCategoryUId === AppEnum.RestDocFileCategoryUId.BankStatement) {
                if (document.uId)
                    setState((prevState) => { return { ...prevState, isBankStatementUploaded: true } });
            }
            if (document.fileCategoryUId === AppEnum.RestDocFileCategoryUId.DigitalMenu) {
                if (document.uId) {

                    let fileType = document?.fileName?.split('.').pop().toLowerCase();
                    setState((prevState) => {
                        return {
                            ...prevState,
                            isMenuUploaded: true, menuPdfBlob: document?.fileUrl,
                            isMenuExcel: (fileType === AppEnum.FileTypes.Xl || fileType === AppEnum.FileTypes.Xls) ? true : false,
                            isMenuPdf: fileType === AppEnum.FileTypes.Pdf ? true : false,
                        }
                    });
                }
            }
            if (document.fileCategoryUId === AppEnum.RestDocFileCategoryUId.AlcoholPermit) {
                if (document.uId) {

                    let fileType = document?.fileName?.split('.').pop().toLowerCase();
                    setState((prevState) => {
                        return {
                            ...prevState,
                            isAlcoholDocUploaded: true, alcoholPdfBlob: document?.fileUrl,
                            isAlcoholFilePdf: fileType === AppEnum.FileTypes.Pdf ? true : false,
                        }
                    });
                }
            }
            if (document.fileCategoryUId === AppEnum.RestDocFileCategoryUId.RestaurantPhoto) {
                if (document.uId)
                    setState((prevState) => { return { ...prevState, isRestaurantDocUploaded: true } });
            }
            if (document.fileCategoryUId === AppEnum.RestDocFileCategoryUId.RestaurantLogo) {
                if (document.uId)
                    setState((prevState) => { return { ...prevState, isRestaurantLogoUploaded: true } });
            }
        });
    }

    // set restaurant document list
    const setRestaurantDocumentList = (restaurantDocuments) => {

        let masterApiData = JSON.parse(localStorage.getItem('languageApiLabels'));

        restaurantDocuments.map(document => {
            if (document.fileCategoryUId === AppEnum.RestDocFileCategoryUId.BankStatement)
                document.fileCategoryName = masterApiData.filter(i => i.key === 'Phase1Document.DocBankStatement')[0]?.value

            if (document.fileCategoryUId === AppEnum.RestDocFileCategoryUId.DigitalMenu)
                document.fileCategoryName = masterApiData.filter(i => i.key === 'Phase1Document.DocDigitalMenu')[0]?.value

            if (document.fileCategoryUId === AppEnum.RestDocFileCategoryUId.AlcoholPermit)
                document.fileCategoryName = masterApiData.filter(i => i.key === 'Phase1Document.DocAlcoholPermit')[0]?.value

            if (document.fileCategoryUId === AppEnum.RestDocFileCategoryUId.RestaurantPhoto)
                document.fileCategoryName = masterApiData.filter(i => i.key === 'Phase1Document.DocRestaurantPhoto')[0]?.value

            if (document.fileCategoryUId === AppEnum.RestDocFileCategoryUId.RestaurantLogo)
                document.fileCategoryName = masterApiData.filter(i => i.key === 'Phase1Document.DocRestaurantLogo')[0]?.value
        });

        setState((prevState) => { return { ...prevState, restaurantDocumentList: restaurantDocuments } });
    }

    // it fetches the bank statement data and sets if there is any previous 
    const getBankStatementData = () => {
        documentService.getBankStatement().then((response) => {
            if (response) {
                setState((prevState) => {
                    return {
                        ...prevState,
                        bankStatementData: response,
                        businessName: response?.socialReason,
                        interBankClabe: response?.interbankAccountNumber,
                    }
                });
            }
        })
    }

    // opens the choosen document
    const openRestaurantDocument = (document) => {

        setState((prevState) => {
            return {
                ...prevState,
                chosenDocumentConfig: document,
                isDocumentChosen: true
            }
        });
    }

    // Opens the document list page
    const goToBackPage = () => {
        setState((prevState) => {
            return {
                ...prevState,
                isDocumentChosen: false,
                isChosenDocumentUploaded: false,
                documentFormData: null,
            }
        });
        if (!state.isBankStatementUploaded)
            setState((prevState) => { return { ...prevState, businessName: null, interBankClabe: null, } });

        if (!state.isMenuUploaded)
            setState((prevState) => { return { ...prevState, isMenuPdf: false, isMenuExcel: false, } });

        if (!state.isAlcoholDocUploaded)
            setState((prevState) => { return { ...prevState, isAlcoholFilePdf: false } });
    }

    // it sets the business name state
    const onChangeBusinessNameHandler = (event) => {
        setState((prevState) => { return { ...prevState, businessName: event.target.value } });
        // if (regexForAcceptAllExceptWhitespace.test(event.target.value))
        //     setState((prevState) => { return { ...prevState, businessName: event.target.value, isBusinessNameValid: false } });
        // else
        //     setState((prevState) => { return { ...prevState, isBusinessNameValid: true } });
    }

    // it sets the interbank clabe state
    const onChangeInterbankClabeHandler = (event) => {

        if (event.target.value === '' || numericRegex.test(event.target.value))
            setState((prevState) => { return { ...prevState, interBankClabe: event.target.value } });
    }


    // uploads the current choosen attachment
    const uploadChoosenDocument = (event) => {

        const file = event.target.files[0];
        let fileType = file.name.split('.').pop().toLowerCase();
        let isFileTypeImage = false;
        isFileTypeImage = (fileType === AppEnum.FileTypes.Jpeg || fileType === AppEnum.FileTypes.Jpg || fileType === AppEnum.FileTypes.Png);

        let allowedFileSize = state.chosenDocumentConfig.maxFileSizeBytes;

        // Number(state.labelList.filter(i => i.key === 'Validation.AllowedFileSizeSeller')[0].value) * 1000000;

        if (file !== undefined) {
            if (state.chosenDocumentConfig.fileCategoryUId === AppEnum.RestDocFileCategoryUId.DigitalMenu
                ?
                (isFileTypeImage || fileType === AppEnum.FileTypes.Pdf || fileType === AppEnum.FileTypes.Xl || fileType === AppEnum.FileTypes.Xls)
                :
                state.chosenDocumentConfig.fileCategoryUId === AppEnum.RestDocFileCategoryUId.AlcoholPermit
                    ?
                    (isFileTypeImage || fileType === AppEnum.FileTypes.Pdf)
                    :
                    isFileTypeImage
            ) {

                if (file.size <= allowedFileSize) {
                    setChosenDocument(file, isFileTypeImage);

                    setChosenFileType(fileType, isFileTypeImage);
                }
                else {

                    if (file.size >= allowedFileSize && isFileTypeImage) {
                        commonMethods.compressUploadedFile(file, Number(state.labelList.filter(i => i.key === 'Validation.CompressedFileSize')[0]?.value))
                            .then((blobFile) => {

                                if (blobFile) {
                                    var newFile = new File([blobFile], blobFile.name, { type: blobFile.type });
                                    setChosenDocument(newFile, isFileTypeImage);

                                    setChosenFileType(fileType, isFileTypeImage);
                                }
                            })
                    }
                    else
                        toast.error(state.labelList.filter(i => i.key === 'Validation.AllowedFileSize')[0]?.value);
                }
            }
            else
                showFileTypeError();
        }
    }

    // it displays the file type error toaster according to the document type
    const showFileTypeError = () => {

        state.chosenDocumentConfig.fileCategoryUId === AppEnum.RestDocFileCategoryUId.DigitalMenu
            ?
            toast.error(state.labelList.filter(i => i.key === 'Validation.SupportedFormatLabelXl')[0]?.value)
            :
            state.chosenDocumentConfig.fileCategoryUId === AppEnum.RestDocFileCategoryUId.AlcoholPermit
                ?
                toast.error(state.labelList.filter(i => i.key === 'Validation.SupportedFormatLabelPdf')[0]?.value)
                :
                toast.error(state.labelList.filter(i => i.key === 'Validation.SupportedFormatLabel')[0]?.value)
    }

    // sets the choosend document to state
    const setChosenDocument = (file, isFileTypeImage) => {

        var input = document.getElementById(state.chosenDocumentConfig.fileCategoryUId);
        var fReader = new FileReader();

        fReader.readAsDataURL(input.files[0]);

        fReader.onloadend = (event) => {

            if (!isFileTypeImage) {
                let pdfBlob = event.target.result.substring(28);

                if (state.chosenDocumentConfig.fileCategoryUId === AppEnum.RestDocFileCategoryUId.DigitalMenu)
                    setState((prevState) => { return { ...prevState, menuPdfBlob: pdfBlob } });

                if (state.chosenDocumentConfig.fileCategoryUId === AppEnum.RestDocFileCategoryUId.AlcoholPermit)
                    setState((prevState) => { return { ...prevState, alcoholPdfBlob: pdfBlob } });
            }
            else {
                var img = document.getElementById(state.chosenDocumentConfig.fileCategoryUId + 'image');
                //setting image to preview
                img.src = event.target.result;
            }
        }


        setState((prevState) => {
            return {
                ...prevState,
                documentFormData: file,
                isChosenDocumentUploaded: true
            }
        });
    }

    // it sets the uploaded doc file type state
    const setChosenFileType = (fileType, isFileTypeImage) => {

        if (state.chosenDocumentConfig.fileCategoryUId === AppEnum.RestDocFileCategoryUId.DigitalMenu) {
            if (!isFileTypeImage) {
                setState((prevState) => {
                    return {
                        ...prevState,
                        isMenuPdf: fileType === AppEnum.FileTypes.Pdf,
                        isMenuExcel: (fileType === AppEnum.FileTypes.Xl || fileType === AppEnum.FileTypes.Xls)
                    }
                });
            }
            else
                setState((prevState) => { return { ...prevState, isMenuPdf: false, isMenuExcel: false } });
        }

        if (state.chosenDocumentConfig.fileCategoryUId === AppEnum.RestDocFileCategoryUId.AlcoholPermit) {

            if (!isFileTypeImage)
                setState((prevState) => { return { ...prevState, isAlcoholFilePdf: fileType === AppEnum.FileTypes.Pdf, } });
            else
                setState((prevState) => { return { ...prevState, isAlcoholFilePdf: false } });
        }
    }

    // It opens the uploaded pdf in a new tab
    const previewPdfDocument = (pdfFile, mimeType) => {

        if (mimeType === AppEnum.MimeTypes.pdf)
            window.open(pdfFile);
        else {
            let pdfInNewTab = window.open("");
            pdfInNewTab.document.write(
                "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                encodeURI(pdfFile) + "'></iframe>"
            )
        }
    }

    // it routes to the thank you screen 
    const finalizeUploadingDocs = () => {
        navigate('/seller/thank-you');
    }

    // logouts the session
    const logout = () => {
        setState((prevState) => { return { ...prevState, sureToLogout: true } });
    }

    // prevents logout
    const preventLogout = () => {
        setState((prevState) => { return { ...prevState, sureToLogout: false } });
    }

    // opens modal to confirm for logout
    const confirmLogout = () => {
        setState((prevState) => { return { ...prevState, sureToLogout: false } });

        localStorage.removeItem('token');
        navigate('/login');
    }

    // send the choosen document with all config to the API
    const saveChosenDocument = () => {
        if (state.documentFormData) {

            let formData = new FormData();
            let uploadFileModel = new UploadFileModel();

            let documentConfig = state.chosenDocumentConfig;

            uploadFileModel.uId = documentConfig.uId;
            uploadFileModel.fileName = state.documentFormData.name;
            uploadFileModel.fileCategoryUId = documentConfig.fileCategoryUId;
            uploadFileModel.isUploadedFromBrowser = true;

            formData.append(documentConfig.fileCategoryUId, state.documentFormData);

            formData.append('RestaurantDocument', JSON.stringify(uploadFileModel));

            documentService.saveRestaurantDocument(formData).then((result) => {
                if (result) {
                    let toaster = state.labelList.filter(i => i.key === 'Validation.DocumentUploadedSuccessfully')[0]?.value;
                    toast.success(toaster);
                    goToBackPage();
                    getDocumentJsxList();
                }
            })

        }
        else
            toast.error(state.labelList.filter(i => i.key === 'Phase1Document.PleaseSelectFile')[0]?.value);
    }

    // it saves the bank statement fields and the document
    const saveBankStatementDocs = () => {

        if (state.documentFormData) {

            let formData = new FormData();
            let bankStatementModel = new BankStatementModel();

            let documentConfig = state.chosenDocumentConfig;

            bankStatementModel.uId = state.bankStatementData.uId;
            bankStatementModel.socialReason = state.businessName;
            bankStatementModel.interbankAccountNumber = state.interBankClabe;

            bankStatementModel.fileCategory.uId = documentConfig.uId;
            bankStatementModel.fileCategory.fileCategoryUId = documentConfig.fileCategoryUId;
            bankStatementModel.fileCategory.fileName = state.documentFormData.name;
            bankStatementModel.fileCategory.isUploadedFromBrowser = true;

            formData.append(documentConfig.fileCategoryUId, state.documentFormData);

            formData.append('BankStatement', JSON.stringify(bankStatementModel));

            if (documentConfig.isApproved === false && documentConfig.uId) {

                documentService.updateBankStatement(formData).then((result) => {
                    if (result) {

                        let toaster = state.labelList.filter(i => i.key === 'Validation.DocumentUploadedSuccessfully')[0]?.value;
                        toast.success(toaster);
                        goToBackPage();
                        getDocumentJsxList();
                        getBankStatementData();
                    }
                })
            }
            else {
                documentService.saveBankStatement(formData).then((result) => {
                    if (result) {

                        let toaster = state.labelList.filter(i => i.key === 'Validation.DocumentUploadedSuccessfully')[0]?.value;
                        toast.success(toaster);
                        goToBackPage();
                        getDocumentJsxList();
                        getBankStatementData();
                    }
                })
            }
        }
        else
            toast.error(state.labelList.filter(i => i.key === 'Phase1Document.PleaseSelectFile')[0]?.value);
    }

    return (
        <Fragment>
            <RestaurantDocument
                state={state}
                openRestaurantDocument={openRestaurantDocument}
                goToBackPage={goToBackPage}
                onChangeBusinessNameHandler={onChangeBusinessNameHandler}
                onChangeInterbankClabeHandler={onChangeInterbankClabeHandler}
                uploadChoosenDocument={uploadChoosenDocument}
                previewPdfDocument={previewPdfDocument}
                finalizeUploadingDocs={finalizeUploadingDocs}
                logout={logout}
                preventLogout={preventLogout}
                confirmLogout={confirmLogout}
                saveChosenDocument={saveChosenDocument}
                saveBankStatementDocs={saveBankStatementDocs}
            />
        </Fragment>
    )

}

export default RestaurantDocumentContainer;