export const UploadIconSvg = <svg width="25" height="20" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 16V18.6667C1 19.7712 1.89543 20.6667 3 20.6667H13.25C14.2165 20.6667 15 19.8832 15 18.9167V18.9167V16" stroke="#FF5100" strokeWidth="2" strokeLinecap="round" />
    <path d="M7 13C7 13.5523 7.44772 14 8 14C8.55228 14 9 13.5523 9 13H7ZM8.70711 0.292893C8.31658 -0.0976305 7.68342 -0.0976305 7.29289 0.292893L0.928932 6.65685C0.538408 7.04738 0.538408 7.68054 0.928932 8.07107C1.31946 8.46159 1.95262 8.46159 2.34315 8.07107L8 2.41421L13.6569 8.07107C14.0474 8.46159 14.6805 8.46159 15.0711 8.07107C15.4616 7.68054 15.4616 7.04738 15.0711 6.65685L8.70711 0.292893ZM9 13L9 1H7L7 13H9Z" fill="#FF5100" />
</svg>

export const ExcelIconSvg = <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    width="96" height="96"
    viewBox="0 0 48 37"
    style={{ fill: '#000000' }}>
    <path fill="#169154" d="M29,6H15.744C14.781,6,14,6.781,14,7.744v7.259h15V6z"></path>
    <path fill="#18482a" d="M14,33.054v7.202C14,41.219,14.781,42,15.743,42H29v-8.946H14z"></path>
    <path fill="#0c8045" d="M14 15.003H29V24.005000000000003H14z"></path>
    <path fill="#17472a" d="M14 24.005H29V33.055H14z"></path>
    <g><path fill="#29c27f" d="M42.256,6H29v9.003h15V7.744C44,6.781,43.219,6,42.256,6z"></path>
        <path fill="#27663f" d="M29,33.054V42h13.257C43.219,42,44,41.219,44,40.257v-7.202H29z">
        </path><path fill="#19ac65" d="M29 15.003H44V24.005000000000003H29z"></path>
        <path fill="#129652" d="M29 24.005H44V33.055H29z"></path></g>
    <path fill="#0c7238" d="M22.319,34H5.681C4.753,34,4,33.247,4,32.319V15.681C4,14.753,4.753,14,5.681,14h16.638 C23.247,14,24,14.753,24,15.681v16.638C24,33.247,23.247,34,22.319,34z"></path>
    <path fill="#fff" d="M9.807 19L12.193 19 14.129 22.754 16.175 19 18.404 19 15.333 24 18.474 29 16.123 29 14.013 25.07 11.912 29 9.526 29 12.719 23.982z"></path>
</svg >

