// Environment Configuration object to store details of server urls
// for dev server use : dev
// for test server use : test
// for production server use : pre

const env = 'test';

const LogisticApiUrl = `https://${env}-api.vooyapp.com/api/`;
const SellerApiUrl = `https://${env}-seller.vooyfood.com/api/v1/`;
const OtpApiUrl = `https://${env}-otp.vooyapp.com/api/v1/`;
const TokenApiUrl = `https://${env}-token.vooyapp.com/`;
const MasterApiUrl = `https://${env}-master.vooyapp.com/api/v1/`;
const FileAPIUrl = `https://${env}-file.vooyapp.com/api/v1/`;

const Environment = {
  
  getUrl() {
    return SellerApiUrl;
  },
  getLogisticApiUrl() {
    return LogisticApiUrl;
  },
  getOtpAPIUrl() {
    return OtpApiUrl;
  },
  getTokenAPIUrl() {
    return TokenApiUrl;
  },
  getVooyMasterServiceUrl() {
    return MasterApiUrl;
  },
  getFileAPIUrl() {
    return FileAPIUrl;
  },
};

export { Environment };

