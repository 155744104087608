import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import forgotPassState, { resetPassFormValidationSchema } from './ForgotPasswordState';
import ResetPassword from "../../component/forgot-password/ResetPassword";
import { toast } from "react-toastify";
import * as loginService from '../../service/auth/login/Login.Service';
import { AppEnum } from "../../common/AppEnum";
import * as commonMethods from '../../common/CommonMethods';

const ResetPasswordContainer = () => {

    const isComponentMounted = useRef(true);
    const location = useLocation();
    const navigate = useNavigate();
    const [state, setState] = useState(forgotPassState);
    const formikRef = useRef();

    // sets several necessary states during component mounting
    useEffect(() => {

        let masterApiData = JSON.parse(localStorage.getItem('languageApiLabels'));
        if (isComponentMounted.current) {
            let validationSchema = resetPassFormValidationSchema(masterApiData);
            setState((prevState) => { return { ...prevState, labelList: masterApiData, resetPassFormValidationSchema: validationSchema } });
        }
        if (!location.state?.otpuId)
            navigate('/forgot-password')

        window.scrollTo(0, 0);
        return () => {
            isComponentMounted.current = false;
        }
    }, [])

    // Resends the otp to the registered mobile number and sets the new otp UId
    const onReSendOTP = () => {

        let formData = new FormData();
        let languageCode = localStorage.getItem('languageCode');

        formData.append('username', location.state?.userName);
        formData.append('secureActionTypeCD', AppEnum.SecureActionTypeCD.forgotPassword);
        formData.append('linkTableCD', AppEnum.LinkTableCD.Seller);
        formData.append('languageCode', languageCode);

        loginService.forgotPassword(formData).then(res => {
            if (res) {
                location.state.otpuId = res.uId;
                setState((prevState) => {
                    return {
                        ...prevState,
                        isOtpResent: true,
                    }
                });
                if (formikRef.current) 
                    formikRef.current.setFieldValue("otp", '');

                toast.success(state.labelList.filter(i => i.key === 'Validation.OtpSentSuccessfully')[0]?.value)
            }
        })
    }

    // It checks the strength of the password and displays it
    const onchangePasswordHandler = (password) => {

        if (password.length >= 6) {
            let passwordStrength = commonMethods.checkPasswordStrength(password);
            setState((prevState) => {
                return {
                    ...prevState, passwordStrength: passwordStrength,
                    isPasswordStrength: true
                }
            });
        }
        else
            setState((prevState) => { return { ...prevState, passwordStrength: {}, isPasswordStrength: false } });
    }

    // Sets the form data and changes the password then routes to login
    const onSubmit = (sellerDetail) => {
        
        let formData = new FormData();

        formData.append('otp_uid', location.state.otpuId);
        formData.append('sms_otp', "");
        formData.append('email_otp', sellerDetail.otp);
        formData.append('new_password', sellerDetail.userPwd);
        formData.append('secureActionTypeCD', AppEnum.SecureActionTypeCD.forgotPassword);

        loginService.resetPassword(formData).then((res) => {
            if (res) {
                navigate('/login');
                toast.success(state.labelList.filter(i => i.key === 'Validation.PasswordUpdatedSuccessFully')[0]?.value);
            };
        })
    }

    return (
        <React.Fragment>
            <ResetPassword
                state={state}
                onSubmit={onSubmit}
                onchangePasswordHandler={onchangePasswordHandler}
                onReSendOTP={onReSendOTP}
                formikRef={formikRef}
            />
        </React.Fragment>
    )
}

export default ResetPasswordContainer;