import React from 'react';
import VooyFoodNewLogo from '../../../asset/logos/VooyFoodNewLogo.png';
import './HeaderModule.css';

const Header = () => {

    return (
        <React.Fragment>
            <div className='header-partner-div'>                
                <img className='vooy-food-svg' src={VooyFoodNewLogo} alt='VooyFoodLogo' />
            </div>
        </React.Fragment>
    )
}

export default Header;