import * as Yup from 'yup';
// import { translateViaApi } from '../../common/translator';

const restaurantInfo = () => {
    return {
        restaurantMobileNo: '',
        restaurantName: '',
        zipCode: '',
        configTaxRegimeNo: '',
        businessName: '',
        rfc: '',
        legalRepresentativeFirstName: '',
        legalRepresentativeLastName: '',
        legalRepresentativenConfigNo: '',
        legalRepresentativeNumber: '',
    }
}

export const regexForAcceptAllExceptWhitespace = /^[^\s].*/;
// const alpha = /^[a-zA-Zñáéíóúü][a-zA-Zñáéíóúü\s]*$/;
const regexMobileNumber = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const regexRfc = /^[A-Za-z0-9]+$/

// User detail form validation schema
export const formValidationSchema = (res, minMobileLength, maxMobileLength) => Yup.object().shape({

    restaurantMobileNo: Yup.string()
        .matches(regexMobileNumber, { message: res?.filter(i => i.key === 'Validation.RestNumberInvalid')[0]?.value, excludeEmptyString: false })
        .required(res?.filter(i => i.key === 'Validation.BillingFieldsAreRequired')[0]?.value)
        .min(minMobileLength, res?.filter(i => i.key === 'Validation.RestNumberInvalid')[0]?.value)
        .max(maxMobileLength, res?.filter(i => i.key === 'Validation.RestNumberInvalid')[0]?.value),

    restaurantName: Yup.string()
        .matches(regexForAcceptAllExceptWhitespace, { message: res?.filter(i => i.key === 'Validation.NameIsInvalid')[0]?.value, excludeEmptyString: true })
        .required(res?.filter(i => i.key === 'Validation.BillingFieldsAreRequired')[0]?.value)
        .max(50),
    zipCode: Yup.string()
        .min(5, res?.filter(i => i.key === 'Validation.PotalCodeLengthError')[0]?.value)
        .required(res?.filter(i => i.key === 'Validation.BillingFieldsAreRequired')[0]?.value),
    configTaxRegimeNo: Yup.string()
        .required(res?.filter(i => i.key === 'Validation.BillingFieldsAreRequired')[0]?.value),
    businessName: Yup.string()
        .required(res?.filter(i => i.key === 'Validation.BillingFieldsAreRequired')[0]?.value),
    rfc: Yup.string()
        .matches(regexRfc, { message: res?.filter(i => i.key === 'Validation.RfcIsInvalidError')[0]?.value, excludeEmptyString: true })
        .required(res?.filter(i => i.key === 'Validation.BillingFieldsAreRequired')[0]?.value)
        .min(12, res?.filter(i => i.key === 'Validation.RfcLengthError')[0]?.value)
        .max(13, res?.filter(i => i.key === 'Validation.RfcLengthError')[0]?.value),
    legalRepresentativeFirstName: Yup.string()
        .required(res?.filter(i => i.key === 'Validation.BillingFieldsAreRequired')[0]?.value),
    legalRepresentativeLastName: Yup.string()
        .required(res?.filter(i => i.key === 'Validation.BillingFieldsAreRequired')[0]?.value),
    legalRepresentativenConfigNo: Yup.string()
        .required(res?.filter(i => i.key === 'Validation.BillingFieldsAreRequired')[0]?.value),
    legalRepresentativeNumber: Yup.string()
        .required(res?.filter(i => i.key === 'Validation.BillingFieldsAreRequired')[0]?.value),
});

// States
const restaurantState = (function states() {
    return {
        labelList: [],
        restaurantInfo: restaurantInfo(),
        countryCode: '',
        countryList: [],
        serviceTypeList: [],
        taxRegimeList: [],
        legalIneList: [],
        taxRegimeLegalConfig: [],
        taxRegimePersonalConfig: [],
        formValidationSchema: formValidationSchema(),
        restaurantAddress: '',
        latLng: null,
        fileCategories: [],
        fileBlobList: [],
        serviceTypeCDs: [],
        isPersonalTaxRegime: false,
        isLegalTaxRegime: false,
        isFrontIneUploaded: false,
        isBackIneUploaded: false,
        isTaxIdUploaded: false,
        isAttorneyUploaded: false,
        isConstitutiveActUploaded: false,
        isDropDownOpen: false,
        addressApprovalCD: false,
        adressRemark: null,
        remarks: null,
        isSellerProfileRejected: true,
        isFrontInePdf: false,
        isBackInePdf: false,
        isTaxIdPdf: false,
        isAttorneyPdf: false,
        isConstitutiveActPdf: false,
        ineFrontPdfBlob: '',
        ineBackPdfBlob: '',
        taxIdPdfBlob: '',
        attorneypdfBlob: '',
        constitutivePdfBlob: '',
        mobileNoMaxLength: null,
        countryNameCode: ''
    }
})()

export default restaurantState;