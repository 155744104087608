import React from 'react';
import VooyFoodNewLogo from '../../../asset/logos/VooyFoodNewLogo.png';
import './AuthHeader.css';

const AuthHeader = (props) => {

    let masterData = JSON.parse(localStorage.getItem('languageApiLabels'));

    return (
        <React.Fragment>
            
            <div className='header-auth-div'>

                <img className='vooy-food-svg' src={VooyFoodNewLogo} alt='VooyFoodLogo' />

                <div className="header-btn-div">
                    <button type="button" onClick={() => props.logout()}>{masterData.filter(i => i.key === "Login.LogoutButton")[0]?.value}</button>
                </div>
            </div>

        </React.Fragment>
    )
}

export default AuthHeader;