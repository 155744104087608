export const GoogleApiKey = () => {
    return {
        googleApiKey: "AIzaSyA6pyIkJXN3Bq-zxTZvd4sEFyEjj2bnCFI",
    }
}
export const LanguageApiUserInterfaceCD = () => {
    return {
        Partner_Registration_Web: "Partner_Registration_Web",
        WEB_FOOD_SELLER_APP: 'WEB_FOOD_SELLER_APP'
    }
}
export const LanguageListCD = () => {
    return {
        English: 'English',
        Spanish: 'Spanish',
    }
}
export const LanguageCodeCD = () => {
    return {
        mexican: "es-MX",
        us: "en-US"
    }
}
export const LinkTableCD = () => {
    return {
        driver: "DLP",
        AmbulanceDriver: 'AMB',
        Customer: 'CST',
        Doctor: 'DCT',
        DeliveryPartner: 'DLP',
        Nurse: 'NUR',
        Seller: 'SLR'
    }
}
export const LoginCD = () => {
    return {
        seller: "SELLER_PARTNER_LOGIN",
        driver: "DELIVERY_PARTNER_LOGIN",
        EMAIL_MOBILE_CHANGE: 'EMAIL_MOBILE_CHANGE',
        createNewAccount: 'CREATE_NEW_ACCOUNT'
    }
}
export const PartnerTypeCD = () => {
    return {
        driver: "driver",
        customer: "customer",
        seller: "seller",
        doctor: "doctor",
        nurse: "nurse",
        ambulanceDriver: "ambulanceDriver"
    }
}
export const TaxInfoCode = () => {
    return {
        TaxRegime: "TAX_REGIME",
        taxDoc: "TAX_DOCUMENT",
        Fiel: "ADVANCE_ELECTRONIC_CERTIFICATE",
        FielKeyTitle: "ADVANCE_ELECTRONIC_KEY",
        FielPassword: "ADVANCE_ELECTRONIC_PASSWORD",
        CsdCertificate: "CSD_CERTIFICATE",
        CsdPassword: "CSD_PASSWORD",
        CsdKeyTitle: "CSD_KEY",
    }
};
export const UserStatusCD = () => {
    return {
        Registered: 101,
        ReviewInProcess: 102,
        Approved: 103,
        Rejected: 104,
        Blocked: 105,
        Suspended: 106,
        Deleted: 107,
        DeletedPermanently: 108
    }
}
export const AttachmentTypeCD = () => {
    return {
        Personal: 21,
        Vehicle: 22,
        Shipment: 23,
        ProfilePic: 24,
        CompletedShipmentItem: 25,
        VehicleMobility: 26,
        AddressProof: 27,
        phase2Documents: 28
    }
}
export const VehicleTypes = () => {
    return {
        Bicycle: "BICYCLE",
        Motorcycle: "MOTORCYCLE",
        Car: "CAR",
        PickUpVan: "PICKUPANDVAN"
    }
}
export const FormDataMobileCD = () => {
    return {
        client_secret: "secret",
        grant_type: "sms_otp",
        client_id: "food_seller_ui",
    }
}
export const SecureActionTypeCD = () => {
    return {
        user: 'USER_REGISTRATION',
        seller: "SELLER_REGISTRATION",
        forgotPassword: "FORGOT_PASSWORD"
    }
}
export const FormDataEmailCD = () => {
    return {
        client_secret: "secret",
        refresh_token: 'refresh_token',
        grant_type: "password",
        client_id: "food_seller_ui",
    }
}
export const AnchorLinks = () => {
    return {
        SatTaxLink: 'https://www.sat.gob.mx/aplicacion/53027/genera-tu-constancia-de-situacion-fiscal.',
        SatRfcLink: 'https://www.sat.gob.mx/aplicacion/operacion/31274/consulta-tu-clave-de-rfc-mediante-curp',
        TAndCEnglish: "https://vooyapp.com.mx/terms-and-conditions/",
        TAndCSpanish: "https://vooyapp.com.mx/terminos-y-condiciones/",
        PolicyEnglish: "https://vooyapp.com.mx/privacy-policies/",
        PolicySpanish: "https://vooyapp.com.mx/politicas-de-privacidad/",
        GenerationAgreement: 'https://vooyapp.com.mx/acuerdo-para-la-generacion-y-envio-de-comprobantes-fiscales-digitales-a-traves-de-internet/',
        SatGovt: "https://www.sat.gob.mx/aplicacion/53027/genera-tu-constancia-de-situacion-fiscal",
    }
}
export const GroupNameCD = () => {
    return {
        SERVICE_TYPE: "SERVICE_TYPE",
        TAX_REGIME: "TAX_REGIME",
        SELLER_LEGAL_REPRESENTATIVE: 'SELLER_LEGAL_REPRESENTATIVE'
    }
}
export const FileRelationTableIds = () => {
    return {
        TaxRegimeLegal: 14,
        TaxRegimePersonal: 15
    }
}
export const ConfigTaxRegimeNo = () => {
    return {
        TaxRegimeLegal: '61',
        TaxRegimePersonal: '60'
    }
}
export const FoodApprovalStatusCD = () => {
    return {
        Registered: 'C',
        Approved: 'A',
        Deleted: 'D',
        Pending: 'P',
        Rejected: 'R',
        PermanentlyDeleted: 'PD'
    }
}
export const RegimeDocumentCategoryCode = () => {
    return {
        INEFront: 'INE Front Photography',
        INEBack: 'INE Back Photography',
        TaxId: 'Tax Identification Card',
        PowerOfAttorney: 'Power of Attorney',
        ArticleIncorporation: 'Articles of Incorporation'
    }
}
export const RestaurantAttachmentUId = () => {
    return {
        Passport: 'RES7VAXNYT',
        FSSAI: 'PRDCIA9633',
        AddressProof: 'ADRX1B8KGU',
        GST: 'FSABYYAF7M',
        FoodSafety: 'F1B7426936',
        INE: '6730D28436',
        IneFrontPhotography: 'SELPRE8WT0L',
        IneBackPhotography: 'SELPRE2468R',
        TaxIdentificationCard: 'SELPRE4456T',
        PowerofAttorney: 'SELPRE5467Q',
        ArticlesofIncorporation: 'SELPRESSDF2',
    }
}

export const Phase2FileCategoryUId = () => {
    return {
        Tax_Incorporation_Regime: 'TAXIDE7890A',
        Other_Income_Regime: 'TAXIDE7654U',
        Regime_For_Individuals_With_Business: 'TAXIDE5678T',
        GeneralRegimeOfLaw: 'TAXIDE3456T',
        CSD_Certificate_key: 'TAXID4564RT',
        CSD_Certificate: 'TAXIDY76545',
        FIEL_Certificate_key: 'TAXIDE8877Y',
        FIEL_Certificate: 'TAXIDE897U',
        Proof_Of_Tax: 'TAXIDE7767Y',
        Mobility_Institute_approval: 'TAXIDE6578',
        ICET_Knowledge_Test: 'TAXIDE5566',
        Toxicological: 'TAXIDE8976',
    }
}

export const FileType = () => {
    return {
        Image: 'IMAGE',
        Pdf: 'PDF'
    }
}

export const RestaurantDocumentCode = () => {
    return {
        SsnNumber: "SSN_NUMBER",
        IdentityDocument: "IDENTITY_DOCUMENT",
        ProofOfAddress: "PROOF_OF_ADDRESS",
        DL: "DRIVING_LICENSE",
        BirthCertificate: "BIRTH_CERTIFICATE",
    }
}

export const RestDocFileCategoryUId = () => {
    return {
        BankStatement: "1787B36CB4",
        RestaurantLogo: "266E6DAFB4",
        RestaurantPhoto: "352753E8B4",
        AlcoholPermit: "416271B8B4",
        DigitalMenu: "4D9D8873B4",
        BannerImage: "5C23374537",
        Image1: "57F2A0D136",
        Image2: "5C57374536",
        RestaurantDefaultImage: "6730FJ8436",
    }
}

export const IdentityStatusCD = () => {
    return {
        ReviewInProcess: 281,
        Approved: 282,
        Rejected: 283,
    }
}

export const FileTypes = () => {
    return {
        Jpg: 'jpg',
        Jpeg: 'jpeg',
        Png: 'png',
        Pdf: 'pdf',
        Xl: 'xlsx',
        Xls: 'xls'
    }
}

export const MimeTypes = () => {
    return {
        pdf: 'application/pdf',
    }
}

export const AppEnum = (function states() {
    return {
        GoogleApiKey: GoogleApiKey(),
        LanguageApiUserInterfaceCD: LanguageApiUserInterfaceCD(),
        LanguageListCD: LanguageListCD(),
        LanguageCodeCD: LanguageCodeCD(),
        LinkTableCD: LinkTableCD(),
        LoginCD: LoginCD(),
        PartnerTypeCD: PartnerTypeCD(),
        TaxInfoCode: TaxInfoCode(),
        UserStatusCD: UserStatusCD(),
        AttachmentTypeCD: AttachmentTypeCD(),
        VehicleTypes: VehicleTypes(),
        FormDataMobileCD: FormDataMobileCD(),
        FormDataEmailCD: FormDataEmailCD(),
        AnchorLinks: AnchorLinks(),
        SecureActionTypeCD: SecureActionTypeCD(),
        GroupNameCD: GroupNameCD(),
        FileRelationTableIds: FileRelationTableIds(),
        FoodApprovalStatusCD: FoodApprovalStatusCD(),
        RegimeDocumentCategoryCode: RegimeDocumentCategoryCode(),
        RestaurantAttachmentUId: RestaurantAttachmentUId(),
        ConfigTaxRegimeNo: ConfigTaxRegimeNo(),
        Phase2FileCategoryUId: Phase2FileCategoryUId(),
        FileType: FileType(),
        RestaurantDocumentCode: RestaurantDocumentCode(),
        IdentityStatusCD: IdentityStatusCD(),
        FileTypes: FileTypes(),
        RestDocFileCategoryUId: RestDocFileCategoryUId(),
        MimeTypes: MimeTypes()
    }
})()