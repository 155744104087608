import React from 'react';
import { ErrorMessage } from 'formik';

// An input type='select' component
const ReactstarpFormikInput = (
    {
        field: { ...fields },
        form: { touched, errors, setFieldValue, ...rest },
        ...props
    }) => (
    <React.Fragment>
        <select {...props} {...fields}
            onBlur={props.onBlur}
            className={'form-control' + (touched[fields.name] && errors[fields.name] ? ' is-invalid' : '')}
            style={{ backgroundImage: 'none' }}
            // invalid={Boolean(touched[fields.name] && errors[fields.name])} selected={props.value === item.value}
            onChange={(event) => {
                setFieldValue(fields.name, event.target.value);
                props.onChange(event.target.value, fields.name);
            }}>
            {
                props.option.map((item, index) => <option key={index} value={item.value}
                    style={{ display: item.value ? 'block' : 'none' }}>{item.label}</option>)
            }
        </select>
        <ErrorMessage name={[fields.name]} component="div" className="invalid-feedback" />
    </React.Fragment>
);
export default ReactstarpFormikInput;