import React, { useEffect, useRef, useState } from "react";
import loginState, { formValidationSchemaMobile } from './LoginState';
import * as  commonService from '../../service/common/CommonService';
import Geocode from "react-geocode";
import * as commonMethods from '../../common/CommonMethods';
import * as  languageService from '../../service/common/LanguageService';
import { useNavigate } from "react-router-dom";
import { AppEnum } from "../../common/AppEnum";
import LoginWithMobile from "../../component/login/LoginWithMobile";
import 'react-toastify/dist/ReactToastify.css';

let countryCode = null;

const LoginWithMobileContainer = () => {

    const isComponentMounted = useRef(true);
    const navigate = useNavigate();
    const [state, setState] = useState(loginState);
    const formikRef = useRef();

    // sets several necessary states during component mounting
    useEffect(() => {
        let masterApiData = JSON.parse(localStorage.getItem('languageApiLabels'));
        commonMethods.removeAsyncVehicleType();

        if (isComponentMounted.current) {
            getActiveCountryList();
            getLanguageList();
            setState((prevState) => { return { ...prevState, labelList: masterApiData } })
        }
        window.scrollTo(0, 0);
        return () => {
            isComponentMounted.current = false;
        }
    }, [])

    // retrives the language list
    const getLanguageList = () => {

        commonService.getlanguageDropdown()
            .then(res => {

                if (res) {
                    let languageCode = localStorage.getItem('languageCode')
                    if (languageCode === AppEnum.LanguageCodeCD.mexican)
                        res.reverse();

                    setState((prevState) => { return { ...prevState, languageList: res } })
                }
            })
    }

    //This method is used to change apps language via api
    const onChangeLanguageListHandler = (languageCode) => {

        let language = '';
        localStorage.setItem("languageCode", languageCode);

        if (languageCode === AppEnum.LanguageCodeCD.mexican) {
            language = AppEnum.LanguageListCD.Spanish;
            localStorage.setItem("language", language);
        }
        else if (languageCode === AppEnum.LanguageCodeCD.us) {
            language = AppEnum.LanguageListCD.English;
            localStorage.setItem("language", language);
        }

        languageService.getLanguageLabels(language, AppEnum.LanguageApiUserInterfaceCD.WEB_FOOD_SELLER_APP)
            .then(res => {

                if (res) {
                    localStorage.setItem("languageApiLabels", JSON.stringify(res));
                    setState((prevState) => { return { ...prevState, labelList: res } });

                    if (formikRef.current) {
                        formikRef.current.resetForm();
                        setFormValidations(res, state.countryCode);
                    }
                }
            })
    }

    // This method is fired when the country code is changed and the dial code is saved in the state 
    const onChangeCountryListHandler = (countryCode) => {
        if (countryCode) {
            let dialCode = state.countryList.filter(i => i.uId === countryCode)[0].code;
            setState((prevState) => { return { ...prevState, countryCode: dialCode } })
        }
        else
            setState((prevState) => { return { ...prevState, countryCode: '' } });
    }

    // Retrieves the country list and calls the method to get the current position
    const getActiveCountryList = () => {
        //It calls the method which extracts the current country name according to the location
        //Passes the lat long as the parameter to the method
        navigator.geolocation.getCurrentPosition(getCountryByLatLng,
            (error) => {
                // new country changes
                getActiveCountryCode("");
            })
    }


    // It calls the method which extracts the current country name according to the location
    // and sets the country code in state for the respective country 
    const getCountryByLatLng = (position) => {

        Geocode.setApiKey(AppEnum.GoogleApiKey.googleApiKey);
        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
            (response) => {

                response.results.every((element) => {
                    let address = element?.address_components?.filter(item => {
                        if (item?.types?.includes("country")) {
                            return item;
                        }
                    });
                    if (address?.length) {
                        countryCode = address[0]?.short_name;
                        return false;
                    }
                    else
                        return true
                });
                // new country changes
                countryCode = !countryCode ? 'MX' : countryCode;
                getActiveCountryCode(countryCode);
            },
            (error) => {
                // new country changes
                getActiveCountryCode("");
            }
        );
    }

    // sets the retrieved active country code
    const getActiveCountryCode = (countryCode) => {

        commonService.getActiveCountry(countryCode).then(res => {
            if (res) {

                let masterApiData = JSON.parse(localStorage.getItem('languageApiLabels'));
                setFormValidations(masterApiData, res[0]?.label)

                setState((prevState) => {
                    return { ...prevState, countryList: [...res], countryCode: res[0].label, }
                });
            }
        })
    }

    // it sets form validation schema 
    const setFormValidations = (masterApiData, dialCode) => {

        let mobileValidation = commonMethods.getMinMaxMobileLength(masterApiData, dialCode);
        let validationSchema = formValidationSchemaMobile(masterApiData, mobileValidation.minMobileLength, mobileValidation.maxMobileLength);

        setState((prevState) => {
            return {
                ...prevState, mobileNoMaxLength: mobileValidation.maxMobileLength,
                formValidationSchemaMobile: validationSchema
            }
        });
    }

    // Routes to login with email page 
    const onChangeLoginOptionHandler = () => {
        navigate('/login-with-email')
    }

    // Routes to the signup page
    const signUp = () => {
        navigate('/create-account');
    }

    // Retrives the otpUId and routes to the otp page
    const onLogin = (loginDetails) => {

        loginDetails.languageCode = localStorage.getItem('languageCode');
        let otpDetail = {};

        otpDetail.linkTableCD = AppEnum.LinkTableCD.driver;
        otpDetail.linkTableUId = '';
        otpDetail.secureActionTypeCD = AppEnum.LoginCD.driver;
        otpDetail.languageCode = loginDetails.languageCode;
        otpDetail.sendToMobile = true;
        otpDetail.sendToEmail = false;
        otpDetail.email = "";
        otpDetail.mobileNo = state.countryCode + loginDetails.userName;

        commonService.validateLogin(otpDetail.mobileNo, AppEnum.PartnerTypeCD.seller, loginDetails.languageCode)
            .then((res) => {

                if (res) {
                    otpDetail.otpuId = res.uId;
                    navigate('/verify-otp', {
                        state: {
                            otpDetail: otpDetail,
                            loginPartnerType: AppEnum.LoginCD.seller,
                            dialCode: state.countryCode
                        }
                    });
                }
            })
    }

    // It routes to the forgot password page
    const routeToForgotPassword = () => {
        navigate('/forgot-password');
    }

    return (
        <React.Fragment>
            <LoginWithMobile
                state={state}
                onChangeCountryListHandler={onChangeCountryListHandler}
                onChangeLanguageListHandler={onChangeLanguageListHandler}
                onChangeLoginOptionHandler={onChangeLoginOptionHandler}
                signUp={signUp}
                onLogin={onLogin}
                routeToForgotPassword={routeToForgotPassword}
                formikRef={formikRef}
            />
        </React.Fragment>
    )
}

export default LoginWithMobileContainer;